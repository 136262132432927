import React, { useState, useEffect } from "react";

interface WLoaderProps {
    message: string;
}

const WLoader: React.FC<WLoaderProps> = ({ message }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    // Change the active index every 500ms for the rotation effect
    useEffect(() => {
        const interval = setInterval(() => {
            // Utiliser une fonction de mise à jour pour s'assurer de la valeur correcte de activeIndex
            setActiveIndex((prevIndex) => (prevIndex + 1) % 8);
        }, 500);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return (
        <div className="fixed h-screen w-screen top-0 bg-[#00000040] flex flex-row justify-center items-center">
            <div className="flex flex-col justify-center items-center bg-white rounded-[10px] shadow-md w-[800px] h-1/2 relative">
                {/* Clignotement du message */}
                <p className="font-bold mb-20 text-xl text-gray-800 animate-pulse">
                    {message}
                </p>
                <div className="relative w-24 h-24">
                    {/* top */}
                    <div
                        className={`absolute top-[calc(25%/2)] right-1/2 w-6 h-2 rounded-lg rotate-90 ${
                            activeIndex === 0 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-1/4 right-1/4 w-6 h-2 rounded-lg rotate-[-45deg] ${
                            activeIndex === 1 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-1/2 right-[calc(25%/2)] w-6 h-2 rounded-lg rotate-0 ${
                            activeIndex === 2 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-[75%] right-1/4 w-6 h-2 rounded-lg rotate-45 ${
                            activeIndex === 3 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>

                    {/* bottom */}
                    <div
                        className={`absolute top-[calc(75%+(25%/2))] right-1/2 w-6 h-2 rounded-lg rotate-90 ${
                            activeIndex === 4 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-1/4 right-[75%] w-6 h-2 rounded-lg rotate-45 ${
                            activeIndex === 7 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-1/2 right-[calc(75%+(25%/2))] w-6 h-2 rounded-lg rotate-0 ${
                            activeIndex === 6 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`absolute top-3/4 right-[75%] w-6 h-2 rounded-lg rotate-[-45deg] ${
                            activeIndex === 5 ? "bg-green-500" : "bg-gray-300"
                        }`}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default WLoader;
