import React from 'react';
import './styles.css';
import {IWInfoBlockProps} from "./types";


const WInfo: React.FC<IWInfoBlockProps> = ({ children , variant = "info"}) => {
    return (
        <div className="m-auto flex justify-center">
        <div className={`ml-3 mr-3 w-info-container ${variant === 'error' ? 'w-error' : 'w-info'}`}>
            {children}
        </div>
        </div>
    );
};

export default WInfo;
