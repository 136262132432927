import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useLocation, useNavigate} from "react-router-dom";
import {payment, IPaymentRequest, IResponsePayment} from '../../../api/payment';
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import WCheckbox from "../../../components/WCheckbox";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import WInfo from "../../../components/WInfo";
import {useTranslation} from "react-i18next";
import './styles.css';
import StripeForm from "./StripeForm";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) : null;

if (!stripePromise) {
    console.error("Stripe public key is not defined in the environment variables.");
}

interface LocationState {
    priceId: string;
    subscriptionID: number;
    price: string;
    libelle: string;
    year: boolean
}

const PaymentForm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as LocationState | null;

    useEffect(() => {
        // Redirection si location.state est null
        if (!state) {
            navigate('/pricing-plans');
        }
    }, [state, navigate]);

    const {priceId, subscriptionID, price, libelle, year} = location.state as LocationState;
    const {t} = useTranslation();
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [paymentData, setPaymentData] = useState<IResponsePayment | null>(null);

    const [formData, setFormData] = useState<IPaymentRequest>({
        subscriptionID: subscriptionID,
        email: "toto@free.fr",
        nom: "Robert",
        prenom: "Christophe",
        adresse: "22 avenue de la palmeraie",
        ville: "Nice",
        codePostal: "06300",
        mobile: "0612345678",
        societe: "TNG",
        siret: "12345",
        priceID: priceId,
        couponID: "",
    });

    // Configurer la mutation
    const mutation = useMutation<IResponsePayment, Error, IPaymentRequest>((paymentData) => payment(paymentData),
        {
            onSuccess: async (data) => {
                setClientSecret(data.clientSecret);
                setPaymentData(data);
            },
            onError: (error) => {
                console.error("Erreur de paiement : ", error);
            }
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        mutation.mutate(formData);
    };

    if (!formData) {
        return <div>loading</div>;
    }

    return (<div className="flex-row w-svw justify-center items-center max-w-[700px] m-auto">
        {clientSecret && paymentData &&
            <Elements stripe={stripePromise} options={{clientSecret: clientSecret}} >
                <StripeForm price={price} plan={formData} year payment={paymentData} />
            </Elements>}

        <div className="font-bold text-lg mt-3">{t('title-form')}</div>
        <div className="font-normal">{t('details')}</div>

        <WInfo>Votre choix : {libelle} -
            Abonnement {year ? 'annuel' : 'mensuel'} {price} €/{year ? 'an' : 'mois'}</WInfo>

        <div className="flex flex-col mt-8 pf-form-container mb-8">
            {/* User */}
            <div className="flex gap-6 flex-wrap">
                <WInput placeholder={t('ph-firstname')} name="prenom" value={formData.prenom}
                        onChange={handleChange} required/>
                <WInput placeholder={t('ph-lastname')} name="nom" value={formData.nom} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-email')} name="email" value={formData.email} onChange={handleChange}
                        required/>
                <WInput placeholder={t('ph-phone')} name="mobile" value={formData.mobile} onChange={handleChange}
                        required/>
            </div>

            {/* Company */}
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-company')} name="societe" value={formData.societe}
                        onChange={handleChange} required/>
                <WInput placeholder={t('ph-siret')} name="siret" value={formData.siret} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-12 flex-wrap">
                <WInput placeholder={t('ph-addr')} name="adresse" value={formData.adresse} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-city')} name="ville" value={formData.ville} onChange={handleChange}
                        required/>
                <WInput placeholder={t('ph-postal')} name="codePostal" value={formData.codePostal}
                        onChange={handleChange} required/>
            </div>

            <div className="flex gap-6 mt-12">
                <WCheckbox id="dded"
                           label={<>Accepter les conditions générales<span className="text-red-500">*</span></>}/>
            </div>

            <div className="flex justify-between gap-6 mt-16 flex-wrap">
                <WButton variant="primary" className="btn-form" onClick={() => navigate('/pricing-plans')}>
                    <ChevronLeftIcon/>{"Précédent"}
                </WButton>
                <WButton variant="secondary" className="btn-form" onClick={handleSubmit}>
                    {"Payer"}<ChevronRightIcon/>
                </WButton>
            </div>
        </div>
    </div>);
};

export default PaymentForm;