import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import iconObj from "../../../assets/icons/actions-plan2.svg";
import iconWind from "../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../../assets/icons/icon optim/chauffage.svg";
import iconProject from "../../../assets/icons/icon optim/iso-ext.svg";

import dpeA from "../../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../../assets/icons/DPE/DPE G.svg";

import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../redux/slices/projectSlice";
import {useNavigate} from "react-router-dom";
import WObjective from "../../../components/WObjective";
import {useMutation, useQuery} from "react-query";
import {getPlanAction, updateProject} from "../../../api/project";
import {PlanActionData, Project} from "../../../api/interfaces/ProjectInterfaces";

/*
const classeInferior = (classe: string) => {
    const classes = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const index = classes.indexOf(classe);

    return (index === -1 || index === classes.length - 1) ? 'G' : classes[index - 1];
};
 */

const Objective: React.FC = () => {
    const navigate = useNavigate();
    const currentProject = useSelector(selectCurrentProject);
    const classeActuelle = currentProject?.classeEnergetiqueActuel || 'G'; // Valeur par défaut
    const [selectedObjective, setSelectedObjective] = useState<PlanActionData>(); // classeInferior(classeActuelle)
    const [customObjective, setCustomObjective ] = useState<PlanActionData[]>([])

    const {data: planAction} = useQuery(['planAction'], async () => {
        if (!currentProject) return null;

        const planActions = await getPlanAction(currentProject?.id);
        setCustomObjective(planActions.filter((pa) => pa.typePlan === "CUSTOM"));
        if(customObjective.length > 0){
            setSelectedObjective( customObjective[0]);
        }
        return planActions;
    }, {enabled: !!currentProject});

    const {mutate: select} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {

    });

    /*
    const {mutate: selectCustom} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess: () => {
            navigate('/objective');
        }
    });
     */

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    const handleObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedObjective(customObjective.find((pa) => pa.classeEnergetiqueObjectique === e.target.value));
    };

    const handlePrevious = () => {
        navigate('/financial/choice')
    };

    const handleSelect = (objective: string, economieEnergie: string, budget: string, montantAides: string, resteACharge: string) => {
        if (currentProject) {
            select({
                project: {
                    ...currentProject,
                    classeEnergetiqueObjectif: objective,
                    economieEnergie: economieEnergie,
                    budget: budget,
                    montantAides: montantAides,
                    resteACharge: resteACharge,
                }
            });

            navigate('/single');
        }
    };

    // Vérifie si currentProject est null
    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    return (
        <>
            <div className="text-left mb-6">
                <div className="flex mt-12 mx-6">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3">
                        <div className="font-bold text-lg  ml-3 mr-3">Objectif DPE</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Vous pouvez faire évoluer votre DPE actuel. <br/>
                            Choisissez un objectif de DPE. Vous pourrez modifier votre objectif par la suite.
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                    <div className="flex flex-col p-6 md:p-12 ">

                        <div className="flex w-full flex-col justify-center items-center">
                            <div
                                className="flex items-center p-2 space-x-10 justify-center text-sm border-dotted border-2 border-red-500 rounded-lg text-center">
                                <div><img src={dpeImages[classeActuelle]} alt={classeActuelle} className="h-40 m-3"/>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div className="font-bold text-lg mb-3">Votre DPE actuel : {classeActuelle}</div>
                                    Votre actif est considéré comme passoire énergétique.<br/><br/>
                                    Selon l’article de loi climat & résilience, vous ne pourrez plus louer votre actif à
                                    partir
                                    de xxx ni le vendre à partir de xxx
                                </div>
                            </div>
                        </div>

                        <p className="text-lg font-bold mt-12">Choisissez un objectif DPE</p>
                        <p>ou fixez en un personnalisé en dessous des propositions</p>

                        <div className="flex flex-row flex-wrap justify-center mt-12">
                            {planAction && planAction.slice(0, 3).map((action, index) => {
                                return <div key={index}
                                            className="flex flex-col justify-between shadow-md border rounded-lg max-w-[350px] p-8 mx-3 mb-4">
                                    <div>
                                        <div className="font-bold text-lg text-center mb-6">{action.typePlan}</div>

                                        <div className="mb-4">
                                            <WObjective level={classeActuelle}
                                                        objective={action.classeEnergetiqueObjectique}/>
                                        </div>


                                        <div className="text-center mb-14">Avec un
                                            DPE {action.classeEnergetiqueObjectique} vous êtes aux normes au delà de
                                            2034
                                            Pour atteindre votre objectif vous devez économiser minimum
                                            <span
                                                className="font-bold block">{action.previsionEconomie} kWhep / An</span>
                                        </div>

                                        <div className="font-bold text-lg">Travaux à prévoir :</div>

                                        {action.travaux.map((trav, index) => {
                                            const iconMap: Record<string, string> = {
                                                "Isolation intérieurs murs exposés": iconProject,
                                                "Pose double vitrage": iconWind,
                                                "Ventillation": iconVent,
                                                "Chauffage": iconChauf,
                                            };

                                            return (
                                                <React.Fragment key={index}>
                                                    <div
                                                        className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                                    <div className="flex space-x-4">
                                                        <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                             alt="Icon"/>
                                                        <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <div className="font-bold text-lg mt-28">Estimations :</div>

                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                                            className="font-bold"> {action.surfacePerdue} m²</span></p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.budget}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.montantAides}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.resteACharge}</p>

                                        <div className="w-full m-auto flex mt-12">
                                            <WButton variant="secondary" onClick={() => {
                                                handleSelect(action.classeEnergetiqueObjectique,
                                                    action.economieEnergie ? action.economieEnergie : '' ,
                                                    action.budget ? action.budget : '',
                                                    action.montantAides ? action.montantAides : '',
                                                    action.resteACharge ? action.resteACharge : ''
                                                );
                                            }} className="w-full">Sélectionner</WButton>
                                        </div>
                                    </div>
                                </div>
                            })}

                        </div>

                        {customObjective.length> 0 &&
                        <div
                            className="flex flex-row flex-wrap shadow-md border rounded-lg w-full p-12 justify-start items-start  ">
                            <div className="flex flex-col justify-center max-w-[350px]">
                                <div className="font-bold text-lg text-center mb-6 ">Fixer un objectif personnalisé</div>
                                <img src={dpeImages[selectedObjective?.classeEnergetiqueObjectique ? selectedObjective?.classeEnergetiqueObjectique : 'G']} alt={`DPE ${selectedObjective}`}
                                     className="max-h-40 mb-6"/>
                                <select
                                    id="objectif-dpe"
                                    name="objectif-dpe"
                                    value={selectedObjective?.classeEnergetiqueObjectique}
                                    onChange={handleObjectiveChange}
                                    className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm sm:text-sm"
                                >
                                    <option value="Sélectionnez un objectif" />
                                    <option value="A" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "A")}>Objectif DPE : A</option>
                                    <option value="B" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "B")}>Objectif DPE : B</option>
                                    <option value="C" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "C")}>Objectif DPE : C</option>
                                    <option value="D" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "D")}>Objectif DPE : D</option>
                                    <option value="E" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "E")}>Objectif DPE : E</option>
                                    <option value="F" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "F")}>Objectif DPE : F</option>
                                    <option value="G" disabled={!customObjective.some((pa) => pa.classeEnergetiqueObjectique === "G")}>Objectif DPE : G</option>
                                </select>

                                <div
                                    className="flex items-center flex-col mt-12 justify-center text-sm text-left">
                                    Avec un DPE {selectedObjective?.classeEnergetiqueObjectique}, vous êtes aux normes jusqu’à au-delà de 2034<br/>
                                    Pour atteindre votre objectif, vous devez économiser minimum <br/>
                                    <span className="font-bold text-lg mt-3">325 kWhep / An</span><br/>

                                </div>
                            </div>
                            <div className="flex flex-col justify-center max-w-[350px] ">
                                <div className="font-bold text-lg">Travaux à prévoir :</div>

                                {selectedObjective?.travaux.map((trav, index) => {
                                    const iconMap: Record<string, string> = {
                                        "Isolation intérieurs murs exposés": iconProject,
                                        "Pose double vitrage": iconWind,
                                        "Ventillation": iconVent,
                                        "Chauffage": iconChauf,
                                    };

                                    return (
                                        <React.Fragment key={index}>
                                            <div
                                                className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                            <div className="flex space-x-4">
                                                <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                     alt="Icon"/>
                                                <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className="flex flex-col justify-end max-w-[350px]  ">

                                <div className="font-bold text-lg ">Estimations :</div>

                                <div className="border-dotted border-t-2 mt-6 border-gray-300 "></div>
                                <p className="text-[#7C90AA] mt-3 text-sm ">Surface perdue :
                                    <span className="font-bold"> {selectedObjective?.surfacePerdue} m²</span>
                                </p>
                                <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie :</p>
                                <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectedObjective?.economieEnergie}</p>
                                <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectedObjective?.budget}</p>
                                <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectedObjective?.montantAides}</p>
                                <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectedObjective?.resteACharge}</p>

                                <div className="w-full m-auto flex mt-12">
                                    <WButton variant="secondary" onClick={() => {
                                        const selectedPlanAction = customObjective.find((pa) => pa.classeEnergetiqueObjectique === selectedObjective?.classeEnergetiqueObjectique);
                                        handleSelect(selectedPlanAction?.classeEnergetiqueObjectique ? selectedPlanAction?.classeEnergetiqueObjectique : '' ,
                                            selectedPlanAction?.economieEnergie ? selectedPlanAction?.economieEnergie : '',
                                            selectedPlanAction?.budget ? selectedPlanAction?.budget : '',
                                            selectedPlanAction?.montantAides ? selectedPlanAction?.montantAides : '',
                                            selectedPlanAction?.resteACharge ? selectedPlanAction?.resteACharge : ''
                                        );
                                    }}>Sélectionner
                                    </WButton>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="flex flex-col mt-12 justify-start ">
                            <a href="https://wattless.fr/estimation-des-travaux-de-renovation/" target="_blank"
                               rel="noreferrer"
                               className="block text-green-600 font-medium underline mt-3">
                                Comment estimons-nous les travaux à réaliser ?
                            </a>

                            <a href="https://wattless.fr/faire-evoluer-son-dpe-comment-ca-marche/" target="_blank"
                               rel="noreferrer"
                               className="block text-green-600 font-medium underline mt-3">
                                Comment projetons-nous votre objectif DPE ?
                            </a>

                            <div>
                                <WButton variant="primary" onClick={handlePrevious} className="mt-8">Précédent</WButton>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>);
};

export default Objective;