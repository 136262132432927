import axios, {AxiosResponse} from "axios";

const API_PAYMENT_URL = process.env.REACT_APP_API_PAYMENT_URL;
const API_URL = process.env.REACT_APP_API_PROJECT_URL;

// Interface de la requête de paiement
export interface IPaymentRequest {
    subscriptionID: number;
    email: string;
    nom: string;
    prenom: string;
    adresse: string;
    ville: string;
    codePostal: string;
    mobile: string;
    societe: string;
    siret: string;
    priceID: string;
    couponID: string;
}

export interface IResponsePayment {
    paymentIntentID: string;
    clientSecret: string;
    customerID: string;
    subscriptionID: string;
    firstInvoiceID: string;
}

export interface ISubscriptionDetails {
    id: number;
    libelle: string;
    valeur: string;
    subscriptionID: number;
    ordre: number;
}

export interface IOfferMoment {
    id: number;
    typeSubscription: string;
    libelle: string;
    description: string | null;
    prixMensuelHT: number;
    prixAnnuelHT: number;
    codeTVA: string;
    valeurTVA: number;
    prixMensuelTtc: number;
    prixAnnuelTtc: number;
    priceIDMensuelStripe: string;
    priceIDAnnuelStripe: string;
    details: ISubscriptionDetails[];
}

export interface ISpecialOffer {
    id: number;
    type: string;
    code: string;
    valeurEnPourcentage: number;
    startDate: string;
    endDate: string;
}

export interface ISubscriptionPlan {
    offreSpecial: ISpecialOffer[];
    offresDuMoment: IOfferMoment[];
}

export interface ICoupon {
    id: number;               // Identifiant du coupon
    couponID: string;         // Code du coupon
    percent: number;          // Pourcentage de réduction
    duration_in_months: number; // Durée en mois (0 si durée illimitée)
    duration: number;         // Nombre de fois que le coupon peut être utilisé
    codePromoID: number;      // Identifiant du code promotionnel associé
}

export interface ICreateAccount {
    nom: string;
    prenom: string;
    email: string;
    mobile: string;
    societe: string;
    siret: string;
    adresse: string;
    ville: string;
    codePostal: string;
    secretKey: string;
    subscriptionID: number;
    mensuelle: boolean;
    customerIDStripe: string;
    subscriptionIDStripe: string;
    firstInvoiceIDStripe: string;
}

export const payment = async (payment: IPaymentRequest): Promise<IResponsePayment> => {
    const response: AxiosResponse<IResponsePayment> = await axios.post('/stripe/payment-wattless', payment, {
        baseURL: API_PAYMENT_URL,
    });
    return response.data;
};

export const getPlan = async (): Promise<ISubscriptionPlan> => {
    const response: AxiosResponse<ISubscriptionPlan> = await axios.get('/subscription', {
        baseURL: API_URL,
    });
    return response.data;
};

export const createAccount = async (data: ICreateAccount): Promise<unknown> => {
    const response: AxiosResponse<ISubscriptionPlan> = await axios.post('/users/create/client', data,{
        baseURL: API_URL,
    });
    return response.data;
};

export const validateCoupon = async (coupon: string) => {
    const response: AxiosResponse<ICoupon | null> = await axios.get(`/stripe/validateCoupon/${coupon}`, {
        baseURL: API_PAYMENT_URL,
    });
    return response.data;
};

export const updateCoupon = async (subscriptionID: string, couponId: string) => {
    const response: AxiosResponse<ICoupon | null> = await axios.post(`/stripe/update-payment-intent-with-coupon`, {
        subscriptionID,
        couponId
    }, {
        baseURL: API_PAYMENT_URL,
    });

    return response.data;
};




