import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICreateAccount} from "../../api/payment";

const initialState: ICreateAccount = {
    nom: '',
    prenom: '',
    email: '',
    mobile: '',
    societe: '',
    siret: '',
    adresse: '',
    ville: '',
    codePostal: '',
    secretKey: '',
    subscriptionID: 0,
    mensuelle: true,
    customerIDStripe: '',
    subscriptionIDStripe: '',
    firstInvoiceIDStripe: '',
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        updateAccount: (state, action: PayloadAction<ICreateAccount>) => {
            Object.assign(state, action.payload);
        },
        resetAccount: () => initialState,
    },
});

// Sélecteur pour obtenir le projet actuel
export const selectAccount = (state: { account : ICreateAccount }) => {
    return state.account;
};

export const {updateAccount, resetAccount} = accountSlice.actions;
export default accountSlice.reducer;
