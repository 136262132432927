import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "react-query";
import {listProjects, removeProject} from "../../../api/project";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { initProjects, setSelectedProject} from "../../../redux/slices/projectSlice";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import WObjective from "../../../components/WObjective";
import {RectangleGroupIcon, TableCellsIcon} from "@heroicons/react/16/solid";
import WLoader from "../../../components/WLoader/index.";
import { PiEyeFill } from "react-icons/pi";
import { FaRegTrashCan } from "react-icons/fa6";
import WModalConfirmation from "../../../components/WModalConfirmation";
import {Project} from "../../../api/interfaces/ProjectInterfaces";
import WToast from "../../../components/WToast";

//const getMap = process.env.GET_MAP;

const ProjectsList: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coordinatesList, setCoordinatesList] = useState<{ lat: number, lng: number }[]>([]);
    const [isCard, setIsCard] = useState(true);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [selectProject, setSelectProject] = useState<Project>();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState<"success" | "error" | "info">("success");

    const handleShowToast = (type: "success" | "error" | "info", message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Ajoute l'événement 'resize' à la fenêtre
        window.addEventListener('resize', handleResize);

        // Nettoie l'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Fonction pour récupérer les coordonnées géographiques depuis une adresse via OpenStreetMapProvider
    /*
    const fetchCoordinatesFromAddress = async (address: string) => {
        const provider = new OpenStreetMapProvider();
        try {
            const results = await provider.search({query: address});
            if (results && results.length > 0) {
                const {x: lng, y: lat} = results[0]; // Récupération des premières coordonnées disponibles
                return {lat, lng};
            } else {
                console.error("Adresse introuvable:", address);
                return null;
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées:", error);
            return null;
        }
    };

     */

    const {data: projects, isLoading} = useQuery('Projects', async () => {
        const projectData = await listProjects();
        // Récupérer les coordonnées pour chaque projet

        dispatch(initProjects(projectData));

        const coordinatesPromises = projectData.map(async (project) => {
            if (project.adresse.latBAN && project.adresse.lngBAN) {
                // Utiliser les coordonnées existantes si elles sont disponibles
                return {lat: project.adresse.latBAN, lng: project.adresse.lngBAN};
            } else {
                return {lat: 0, lng: 0};
/*
                if (getMap) {
                    // Si les coordonnées sont nulles, utiliser le géocodage
                    const address = `${project.adresse.adresse}, ${project.adresse.ville}, ${project.adresse.codePostal}`;
                    const coords = await fetchCoordinatesFromAddress(address);

                    return coords || {lat: 0, lng: 0}; // Valeur par défaut si aucune coordonnée n'est trouvée
                } else {
                    return {lat: 0, lng: 0};
                }

 */
            }
        });

        const validCoordinates = await Promise.all(coordinatesPromises);
        const filteredCoordinates = validCoordinates.filter(coord => coord.lat !== 0 && coord.lng !== 0);
        setCoordinatesList(filteredCoordinates); // Mettre à jour l'état avec les coordonnées valides


        return projectData; // Retourne les données de projet
    });

    const {mutate} = useMutation(async () => {
        return await listProjects();
    }, {
        onSuccess: (projectData) => {
            dispatch(initProjects(projectData));
        },
        onError: (error) => {
            console.error('Project creation error:', error);
        },
    });

    // Fonction pour supprimer un projet
    const handleDeleteProject = async (projectId: number | undefined) => {
        try {
            if(projectId){
                await removeProject(projectId);
                mutate();
                setShowDialogDelete(false)
                handleShowToast("success", "Projet supprimé avec succès")
            }
        } catch (error) {
            handleShowToast("error", "Une erreure est survenue")
            console.error('Erreur lors de la suppression du projet', error);
        }
    };

    const handleCloseDialogDeleteProject = async () => {
        setShowDialogDelete(false);
    }

    const handleOpenDialogDeleteConfirm = async (project: Project) => {
        setShowDialogDelete(true);
        setSelectProject(project)
    };

    const handleNewProject = async () => {
        navigate('/ademe');
    };

    const handleGoProject = async (projectId: number) => {
        dispatch(setSelectedProject(projectId));
        navigate('/single');
    }

    // Création du marqueur personnalisé avec une lettre (ex: "A")
    const customMarkerIcon = L.divIcon({
        html: '<div class="custom-marker"><span class="marker-letter">A</span></div>',
        className: 'custom-div-icon',
        iconSize: [25, 25],
        iconAnchor: [12, 12],
    });

    if(showDialogDelete){
        return <WModalConfirmation
            isOpen={showDialogDelete}
            title={`Êtes-vous certain de vouloir effacer votre projet "${selectProject?.libelle}" ?`}
            message={`Cette action est irréversible et entrainera l'effacement définitif de votre projet ainsi que des données qui lui sont liées.`}
            titleConfirme="Confirmer"
            titleCancel="Abandonner"
            onConfirm={() => handleDeleteProject(selectProject?.id)}
            onCancel={() => handleCloseDialogDeleteProject()}
            onClose={() => handleCloseDialogDeleteProject()}
        />
    }

    if(isLoading) {
        return <WLoader message={'Chargement'} />;
    }

    const handleHideToast = () => setShowToast(false);

    return (
        <>
            {/* Affichage de la carte avec tous les marqueurs */}
            {coordinatesList.length > 0 && (
                <div className="h-[350px] w-full">
                    <MapContainer
                        center={coordinatesList[0]} // Centrer sur la première coordonnée valide
                        zoom={10}
                        style={{height: '100%', width: '100%'}}
                        className="w-full p-4 rounded-t-[10px]"
                        bounds={coordinatesList.map(coord => [coord.lat, coord.lng])} // Ajuste automatiquement la carte pour inclure tous les marqueurs
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                        {/* Affichage de tous les marqueurs */}
                        {coordinatesList.map((coord, index) => (
                            <Marker
                                key={index}
                                position={coord}
                                icon={customMarkerIcon}
                            />
                        ))}
                    </MapContainer>
                </div>
            )}

            {projects && (isCard || windowSize.width < 1000) ? <div className="w-full">
                    <div className="flex flex-row flex-wrap justify-between text-center my-8 mx-6 space-y-4">
                        <div className="font-medium flex justify-center items-center text-2xl">Liste de vos
                            projets
                        </div>
                        <div className="flex space-x-4 justify-center items-center">
                            {windowSize.width >= 1000 && <TableCellsIcon className="cursor-pointer h-8 text-[#7c90aa]" onClick={() => setIsCard(false)}/>}
                            <WButton variant="secondary"
                                     onClick={handleNewProject}
                                     className="bottom-4 right-3">Nouveau projet</WButton>
                        </div>
                    </div>
                    <ResponsiveMasonry className="space-x-4 mx-6 " columnsCountBreakPoints={{1: 1, 700: 2, 1150: 3, 1300: 4}}>
                        <Masonry className="space-x-4 ">
                        {projects.map((project, index) => (
                            <div key={index} onClick={() => handleGoProject(project.id)}
                                 className="mb-4 bg-white w-full rounded-[10px] shadow-md p-8 flex flex-row relative">
                                <div className="block flex-col text-left relative w-full">
                                    <div className="font-bold">{project.libelle}</div>
                                    <div>{project.adresse.adresse}</div>
                                    <div className="text-xs">{project.adresse.codePostal} {project.adresse.ville}</div>
                                    <div className="border border-dashed border-gray-500 my-6"></div>
                                    <div className="font-bold mb-2">Objectif DPE</div>
                                    {project.classeEnergetiqueObjectif ?
                                        <WObjective
                                            level={project.classeEnergetiqueActuel}
                                            objective={project.classeEnergetiqueObjectif}/> :
                                        <WObjective
                                            level={project.classeEnergetiqueActuel}
                                            objective={project.classeEnergetiqueActuel}/>
                                    }
                                    <div className="border border-dashed border-gray-500 my-6"></div>
                                    <div className="font-bold">Estimation budget</div>
                                    <div>{project.resteACharge}</div>
                                    <div>{project.aidesFinancieres}</div>
                                </div>
                            </div>
                        ))}
                        </Masonry>
                    </ResponsiveMasonry></div> :
                <div className="text-left w-full p-5 ">
                    <div className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto w-full">

                        {/* Tableau des projets */}
                        {projects && (
                            <div className="rounded-[10px] ">
                                <div className="flex flex-row justify-between text-center m-6">
                                    <div className="font-medium flex justify-center items-center text-2xl">Liste de vos
                                        projets
                                    </div>
                                    <div className="flex space-x-4 justify-center items-center">
                                        {windowSize.width >= 1000 && <RectangleGroupIcon className="cursor-pointer h-8 text-[#7c90aa]"
                                                            onClick={() => setIsCard(true)}/>}
                                        <WButton variant="secondary" onClick={handleNewProject}
                                                 className="bottom-4 right-3">
                                            Nouveau projet
                                        </WButton>
                                    </div>
                                </div>
                                <table className="table-auto text-left w-full rounded-[10px]">
                                    <thead>
                                    <tr className="bg-[#899BB4] text-white h-10">
                                        <th className="p-3 font-bold">Nom du projet</th>
                                        <th className="p-3 font-bold">Adresse</th>
                                        <th className="p-3 font-bold">Objectif DPE</th>
                                        <th className="p-3 font-bold">Estimation budget</th>
                                        <th className="p-3 font-bold">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {projects.map((project, index) => (
                                        <tr key={project.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                            <td className="p-3 font-bold">#{project.id} {project.libelle}</td>
                                            <td className="p-3 font-medium text-sm">
                                                {project.adresse.adresse}<br/>
                                                {project.adresse.ville} {project.adresse.codePostal}
                                            </td>
                                            <td className="flex justify-start items-center p-3 text-center">{project.classeEnergetiqueObjectif ?
                                                <WObjective
                                                    level={project.classeEnergetiqueActuel}
                                                    objective={project.classeEnergetiqueObjectif}/> :
                                                <WObjective
                                                    level={project.classeEnergetiqueActuel}
                                                    objective={project.classeEnergetiqueActuel}/>
                                            }</td>
                                            <td className="p-3">{project.resteACharge}</td>
                                            <td className="p-3">
                                                <button
                                                    className="text-blue-500 hover:underline mr-4 text-2xl "
                                                    onClick={() => handleGoProject(project.id)}
                                                    title="Consulter"
                                                >
                                                    <PiEyeFill />
                                                </button>
                                                <button
                                                    className="text-red-500 hover:underline text-2xl "
                                                    onClick={() => handleOpenDialogDeleteConfirm(project)}
                                                    title="Supprimer"
                                                >
                                                    <FaRegTrashCan />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>}

            {showToast && (
                <WToast
                    message={toastMessage}
                    type={toastType}
                    duration={5000}
                    onClose={handleHideToast}
                />
            )}
        </>
    );
};

export default ProjectsList;
