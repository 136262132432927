import axios from './axiosInstance';
import {User} from "./interfaces/ProjectInterfaces";

export const getUser = async (): Promise<string[]> => {
    const response = await axios.get(`/users`);
    return response.data.data;
};

export const getUserById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
};

export const getUsers = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
    console.log("ininini", user);
    const response = await axios.put(`/users/${user.id}`, user);
    return response.data;
};