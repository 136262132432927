import React from 'react';
import {Outlet} from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import AbandonProjectButton from "./AbandonProjectButton";

const HomeLayout: React.FC = () => {

    return (
        <div className="min-h-screen bg-[#F5F6FA] flex flex-col">
            {/* Header */}
            <header className="w-full h-[65px] bg-white flex items-center justify-between px-6 shadow">
                {/* Logo à gauche */}
                <div className="flex items-center">
                    <Logo className="h-8 w-auto" />
                </div>

                {/* Titre centré */}
                <div className="text-2xl text-gray-700">Nouveau projet</div>

                <AbandonProjectButton />
            </header>

            {/* Contenu principal */}
            <main className="flex flex-col justify-center items-center">
                <Outlet />
            </main>
        </div>
    );
};

export default HomeLayout;