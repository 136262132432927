import React, {useState} from "react";
import WCheckbox from "../../../components/WCheckbox";
import {StarIcon} from "@heroicons/react/16/solid";
import WButton from "../../../components/Wbutton";
import {FiLogOut} from "react-icons/fi";

const Remove: React.FC = () => {
    const [active, setActive] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [rating, setRating] = useState(0); // État local pour la note

    const handleRating = (index: number) => {
        setRating(index); // Mettre à jour la note en fonction de l'étoile cliquée
    };

    return (
        <div>
            <a className="text-gray-400 underline text-sm cursor-pointer" onClick={() => setActive(true)}>Effacer mon
                profil et les données enregistrées</a>
            {active && <div
                className="fixed h-svh w-svw left-0 top-0 bg-[#00000040] flex flex-row justify-center items-center">
                <div className="flex flex-col p-10 bg-white rounded-[10px] shadow-md w-[800px] relative">

                    {confirm ? <>
                            <div className="font-bold mb-3 mx-8">OHHH NON ... Nous sommes désolés de vous voir nous quitter
                                :-(
                            </div>
                            <div className="mx-8">Vos données ont bien été effacées définitivement.<br/>
                                Pour nous aider à nous améliorer et pour comprendre votre départ, merci d’ndiquer la raison
                                pour laquelle vous nous quittez
                            </div>

                            <div className="m-10 mb-1">
                                <WCheckbox id="choise1" labelClassName="mb-3" labelTextClassName="!no-underline !ml-4"
                                           label="C’est trop compliqué"/>
                                <WCheckbox id="choise2" labelClassName="mb-3" labelTextClassName="!no-underline !ml-4"
                                           label="Il manque des fonctionnalités pour atteindre mon objectif"/>
                                <WCheckbox id="choise3" labelClassName="mb-3" labelTextClassName="!no-underline !ml-4"
                                           label="C’est trop cher"/>
                                <WCheckbox id="choise4" labelClassName="mb-3" labelTextClassName="!no-underline !ml-4"
                                           label="J’ai trouvé mieux ailleur"/>
                                <WCheckbox id="choise4" labelClassName="mb-3" labelTextClassName="!no-underline !ml-4"
                                           label="J’ai trouvé mieux ailleur"/>
                                <WCheckbox id="choise5" labelTextClassName="!no-underline !ml-4"
                                           label="Autre raison"/>

                                <div className="mt-6">Comment évaluez-vous notre service ?</div>
                                <div className="flex flex-row m-2">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <StarIcon
                                            key={star}
                                            className={`h-8 cursor-pointer transition-colors duration-200 ${
                                                star <= rating ? 'text-yellow-300' : 'text-gray-200'
                                            }`}
                                            onClick={() => handleRating(star)}
                                        />
                                    ))}
                                </div>
                                <div className="mt-6 mb-3">Laissez-nous un commentaire</div>
                                <textarea
                                    className=" block w-full bg-gray-50 border border-gray-100 h-36 rounded p-3"></textarea>

                                <div className="text-center mt-10">
                                    <WButton onClick={() => {
                                        setConfirm(false);
                                        setActive(false);
                                    }} variant="secondary"
                                             className="!bg-red-600 !border-red-600"><FiLogOut className="h-6 w-6 mr-6"/>Quitter</WButton>
                                </div>
                            </div>
                        </> :
                        <div className="p-8 pt-3">
                            <div className="font-bold">Effacer définitivement votre profil</div>
                            <div className="mt-6">Cette action est irreversible et entrainera l’effacement définitif de votre profil et de votre compte y compris des données qui lui sont liées.</div>
                            <div className="text-center mt-28">
                                <WButton variant="secondary" onClick={() => setConfirm(true)}>Confirmer</WButton>
                                <WButton variant="secondary" onClick={() => {
                                    setConfirm(false);
                                    setActive(false);
                                }} className="!ml-6 !bg-red-600 !border-red-600">Abandonner</WButton>
                            </div>
                        </div>}

                </div>
            </div>}
        </div>
    );
};

export default Remove;
