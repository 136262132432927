import React, { useEffect } from "react";
import './styles.css';

interface ToastProps {
    message: string;
    type?: "success" | "error" | "info" | "warning";
    duration?: number;
    onClose: () => void;
}

const WToast: React.FC<ToastProps> = ({
                                         message,
                                         type = "info",
                                         duration = 4000,
                                         onClose,
                                     }) => {

    useEffect(() => {
        const timer = setTimeout(onClose, duration);
        return () => clearTimeout(timer);
    }, [duration, onClose]);

    const toastColors = {
        success: "bg-green-500",
        error: "bg-red-500",
        info: "bg-blue-500",
        warning: "bg-yellow-500",
    };

    const iconTypes = {
        success: "✅",
        error: "❌",
        info: "ℹ️",
        warning: "⚠️",
    };

    return (
        <div
            className={`fixed right-8 bottom-8 flex items-start w-80 max-w-full p-4 rounded-lg shadow-lg text-white 
      ${toastColors[type]} animate-slide-up-down transition-transform`}
        >
            <span className="text-xl mr-3">{iconTypes[type]}</span>
            <div className="flex-1">
                <p className="font-semibold">{message}</p>
                <div
                    className="h-1 mt-2 bg-white bg-opacity-20 rounded-full relative overflow-hidden"
                    style={{
                        animation: `progress ${duration}ms linear`,
                    }}
                >
                    <div
                        className="absolute top-0 left-0 h-full bg-white"
                        style={{ width: "100%", animation: `fill ${duration}ms linear` }}
                    ></div>
                </div>
            </div>
            <button
                onClick={onClose}
                className="ml-4 text-white opacity-70 hover:opacity-100 transition-opacity"
            >
                ✖️
            </button>
        </div>
    );
};

export default WToast;
