import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import 'leaflet/dist/leaflet.css';
import WButton from "../../../components/Wbutton";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {RectangleGroupIcon, TableCellsIcon} from "@heroicons/react/16/solid";
import WLoader from "../../../components/WLoader/index.";
import {getUsers} from "../../../api/user";

const UsersList: React.FC = () => {

    const [isCard, setIsCard] = useState(true);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Ajoute l'événement 'resize' à la fenêtre
        window.addEventListener('resize', handleResize);

        // Nettoie l'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {data: users, isLoading} = useQuery('Projects', async () => {
        return await getUsers();
    });

    if (isLoading) {
        return <WLoader message={'Chargement'}/>;
    }

    return (
        <>
            {users && (isCard || windowSize.width < 1000) ? <div className="max-w-[1100px] w-full">
                    <div className="flex flex-row flex-wrap justify-between text-center my-8 mx-6 space-y-4">
                        <div className="font-medium flex justify-center items-center text-2xl">
                            Tous vos utilisateurs
                        </div>
                        <div className="flex space-x-4 justify-center items-center">
                            {windowSize.width >= 1000 && <TableCellsIcon className="cursor-pointer h-8 text-[#7c90aa]"
                                                                         onClick={() => setIsCard(false)}/>}
                            <WButton variant="secondary"
                                     className="bottom-4 right-3">Inviter un nouvel utilisateur</WButton>
                        </div>
                    </div>
                    <ResponsiveMasonry className="space-x-4 mx-6 " columnsCountBreakPoints={{1: 1, 700: 2, 1150: 3}}>
                        <Masonry className="space-x-4 ">
                            {users.map((user, index) => (
                                <div key={index}
                                     className="mb-4 bg-white w-full rounded-[10px] shadow-md p-8 flex flex-row relative">
                                    <div className="block flex-col text-left relative w-full">
                                        <div className="font-bold">{user.username}</div>
                                        <div>{user.email}</div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry></div> :
                <div className="text-left w-full p-14 ">
                    <div className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto w-full">

                        {/* Tableau des projets */}
                        {users && (
                            <div className="rounded-[10px] ">
                                <div className="flex flex-row justify-between text-center m-6">
                                    <div className="font-medium flex justify-center items-center text-2xl">
                                        Tous vos utilisateurs
                                    </div>
                                    <div className="flex space-x-4 justify-center items-center">
                                        {windowSize.width >= 1000 &&
                                            <RectangleGroupIcon className="cursor-pointer h-8 text-[#7c90aa]"
                                                                onClick={() => setIsCard(true)}/>}
                                        <WButton variant="secondary"
                                                 className="bottom-4 right-3">
                                            Inviter un nouvel utilisateur
                                        </WButton>
                                    </div>
                                </div>
                                <table className="table-auto text-left w-full rounded-[10px]">
                                    <thead>
                                    <tr className="bg-[#899BB4] text-white h-14">
                                        <th className="p-3 font-bold">Nom & Prénom</th>
                                        <th className="p-3 font-bold">E-mail</th>
                                        <th className="p-3 font-bold">Rôle</th>
                                        <th className="p-3 font-bold">Projects</th>
                                        <th className="p-3 font-bold">Accés</th>
                                        <th className="p-3 font-bold"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((users, index) => (
                                        <tr key={users.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                                            <td className="p-3 font-bold">{users.username}</td>
                                            <td className="p-3 font-bold">{users.email}</td>
                                            <td className="p-3 font-bold">{users.roles[0].name}</td>
                                            <td className="p-3 font-bold"></td>
                                            <td className="p-3 font-bold"></td>
                                            <td className="p-3 font-bold"></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>}
        </>
    );
};

export default UsersList;
