import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";
import WButton from "../../../../components/Wbutton";
import WInput from "../../../../components/WInput";
import {
    IPaymentRequest,
    IResponsePayment,
    updateCoupon,
    validateCoupon
} from "../../../../api/payment";
import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {updateAccount} from "../../../../redux/slices/accountSlice";

interface StripeFormProps {
    plan: IPaymentRequest;
    price: string;
    year: boolean;
    payment: IResponsePayment;
}

const StripeForm: React.FC<StripeFormProps> = ({plan, price, year, payment}) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [percent, setPercent] = useState(0);

    const handleSubmitStripe = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        dispatch(updateAccount({
            nom: plan.nom,
            prenom: plan.prenom,
            adresse: plan.adresse,
            email: plan.email,
            subscriptionID: plan.subscriptionID,
            codePostal: plan.codePostal,
            ville: plan.ville,
            societe: plan.societe,
            siret: plan.siret,
            mobile: plan.mobile,
            secretKey: "123456",
            mensuelle: !year,
            customerIDStripe: payment.customerID,
            subscriptionIDStripe: payment.subscriptionID,
            firstInvoiceIDStripe: payment.firstInvoiceID
        }))

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/confirm-payment`,
            },
        });

        if (error) {
            console.log('ERROR', error.message);
        } else {
            navigate('/confirm-payment');
        }
    };

    const {mutate} = useMutation(async ({coupon}: { coupon: string }) => {
        const couponRes = coupon ? await validateCoupon(coupon) : null;
        plan.couponID = (couponRes) ? coupon : "";
        await updateCoupon(payment.subscriptionID, plan.couponID);
        setPercent(couponRes ? couponRes.percent: 0);
        setCoupon((couponRes) ? coupon : "");
    });

    const handleCoupon = () => {
        setCoupon(coupon);
        mutate({coupon});
    }

    return <div
        className="fixed flex items-center justify-center bg-[#899BB4] bg-opacity-75 w-full h-full z-[80] top-0 left-0">

        <div
            className="flex flex-col md:flex-row justify-items-stretch bg-white p-10 rounded w-full m-1 md:m-10 max-w-[1050px]">
            <div className="flex flex-col justify-between mt-3 mr-20 text-left mb-10 w-full  h-full">
                <div
                    className="mb-14 text-xl">
                    <div
                        className="font-medium">Abonnement {year ? 'annuel' : 'mensuel'} {Number(price) * (1 - percent / 100)} €/{year ? 'an' : 'mois'}</div>
                    <div className="mt-6 text-sm font-medium">Coupon Code</div>
                    <WInput className="mb-3" name="coupon" value={coupon} onChange={(e) => setCoupon(e.target.value)}/>
                    <WButton className="mb-10" variant="secondary" onClick={handleCoupon}>Valider</WButton>
                </div>
                <div className="flex ">
                    <div
                        className="items-center flex justify-center text-3xl mb-3">Total {Number(price) * (1 - percent / 100)} €/{year ? 'an' : 'mois'} </div>
                </div>
            </div>
            <div className="flex flex-col w-full">
                <form onSubmit={handleSubmitStripe}>
                    <PaymentElement/>
                    <WButton variant="secondary" className='mt-6' disabled={!stripe}>Payer</WButton>
                </form>
            </div>
        </div>
    </div>;
};

export default StripeForm;