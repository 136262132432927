import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./redux/slices/authSlice";
import userReducer from "./redux/slices/userSlice";
import projectReducer from "./redux/slices/projectSlice";
import accountReducer from "./redux/slices/accountSlice";

const persistedAccountReducer = persistReducer({ key: "account", storage }, accountReducer);
const persistedAuthReducer = persistReducer({ key: "auth", storage }, authReducer);
const persistedUserReducer = persistReducer({ key: "user", storage }, userReducer);
const persistedProjectReducer = persistReducer({ key: "project", storage }, projectReducer);

export const store = configureStore({
    reducer: {
        account: persistedAccountReducer,
        auth: persistedAuthReducer,
        user: persistedUserReducer,
        project: persistedProjectReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }),
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
