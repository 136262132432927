import React from 'react';
import './styles.css';

interface ModalProps {
    isOpen: boolean;
    title: string;
    message: string;
    titleConfirme: string;
    titleCancel: string;
    onConfirm: () => void;
    onCancel: () => void;
    onClose: () => void;
}

const WModalConfirmation: React.FC<ModalProps> = ({ isOpen, title, message, titleConfirme,
                                                      titleCancel, onConfirm, onCancel, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg w-1/2 p-8 shadow-lg">
                <p className="text-left text-lg font-semibold mb-6">{title}</p>
                <p className="text-left text-lg mb-6">{message}</p>
                <div className="flex justify-around">
                    <button
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                        className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                        {titleConfirme}
                    </button>
                    <button
                        onClick={() => {
                            onCancel();
                            onClose();
                        }}
                        className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    >
                        {titleCancel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WModalConfirmation;
