import React, {useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/images/logo.svg';
import {useSelector, useDispatch} from 'react-redux';
import {selectUser} from "../redux/slices/userSlice";
import {ChevronDownIcon} from '@heroicons/react/16/solid';
import iconFolder from "../assets/icons/folder.svg";
import iconUsers from "../assets/icons/users.svg";
import iconProfil from "../assets/icons/profil.svg";
import {logout} from "../redux/slices/authSlice";

const SingleLayout: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const isActive = (path: string) => location.pathname === path;

    return (
        <div className="min-h-screen bg-[#F5F6FA] flex flex-col">
            {/* Header */}
            <header className="w-full h-[65px] bg-white flex items-center justify-between px-6 shadow">
                {/* Logo à gauche */}
                <div className="flex items-center">
                    <Logo className="h-8 w-auto"/>
                </div>

                <div className="flex space-x-6">
                    <div className="flex align-middle justify-center">
                        <a href="/projects"
                           className={` ${
                               isActive('/projects') ? 'flex justify-center flex-row text-green-600 font-medium underline' : 'flex text-[#7C90AA] justify-center flex-row font-medium'
                           }`}>
                            <img src={iconFolder} className="h-6"/> <div className="ml-3 hidden md:flex">Mes projets</div>
                        </a>
                    </div>
                    <div className="flex align-middle justify-center">
                        <a href="/users"
                           className={` ${
                               isActive('/users') ? 'flex justify-center flex-row text-green-600 font-medium underline' : 'flex text-[#7C90AA] justify-center flex-row font-medium'
                           }`}>
                            <img src={iconUsers} className="h-6"/> <div className="ml-3 hidden md:flex">Utilisateurs</div>
                        </a>
                    </div>
                    <div className="relative flex align-middle justify-center">
                        <span className=" flex text-[#7C90AA] justify-center flex-row font-medium">
                            <img src={iconProfil} className="h-7"/> <div className="ml-3 hidden md:flex">{user && user.username}</div>
                        </span>

                        {/* Icône de flèche vers le bas */}
                        <ChevronDownIcon
                            className="ml-2 h-5 w-5 cursor-pointer text-[#7C90AA]"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        />

                        {/* Menu déroulant */}
                        {isDropdownOpen && (
                            <div className="text-left absolute right-0 mt-10 w-48 bg-white border rounded-lg shadow-lg z-[80]">
                                <ul className="py-1">
                                    <li
                                        className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => {
                                            navigate('/profil/info');
                                        }}
                                    >
                                        Mes infos
                                    </li>
                                    <li
                                        className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                        onClick={handleLogout}
                                    >
                                        Déconnexion
                                    </li>

                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </header>

            {/* Contenu principal */}
            <main className="flex flex-col justify-center items-center">
                <Outlet/>
            </main>
        </div>
    );
};

export default SingleLayout;
