import React from 'react';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";

const ErrorPayment: React.FC = () => {

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">

            <WInfo variant="error">OUPS une erreur est survenue. Votre payement n’a pas abouti</WInfo>

            <div className="flex gap-6 mt-10 m-auto flex-col ml-3 mr-3">
                <WButton variant="secondary">
                    Revenir au payement
                </WButton>
                <WButton>
                    Choisir un autre abonnement
                </WButton>
                <WButton>
                    Abandonner
                </WButton>
            </div>

        </div>);
};

export default ErrorPayment;