import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import PricingPlans from '../pages/Onboarding/PricingPlans';
import PaymentForm from "../pages/Onboarding/PaymentForm";
import ConfirmPayment from "../pages/Onboarding/ConfirmPayment";
import ErrorPayment from "../pages/Onboarding/ErrorPayment";
import ValidEmail from "../pages/Onboarding/ValidEmail";
import ForgotPassword from "../pages/Signin/ForgotPassword";
import GuestRegistration from "../pages/Signin/GuestRegistration";
import Login from "../pages/Signin/Login";
import HomeLayout from "../layouts/HomeLayout";
import PrivateRoute from "./PrivateRoute";
import Ademe from "../pages/Project/Ademe";
import Validation from "../pages/Project/Validation";
import Objective from "../pages/Project/Objective";
import Financial from "../pages/Project/Financial";
import ProjectsList from "../pages/Project/List";
import SingleInfo from "../pages/Single/Info";
import Choice from "../pages/Project/Financial/Choice";
import SingleLayout from "../layouts/SingleLayout";
import UsersList from "../pages/Users/List";
import Info from "../pages/Profil/Info";
import Billing from "../pages/Profil/Billing";

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <Routes>

                <Route path="/" element={<Navigate to="/projects" replace/>}/>

                <Route path="/login" element={<Login/>}/>

                <Route path="/" element={<MainLayout hasBanner/>}>
                    <Route index path="pricing-plans" element={<PricingPlans/>}/>
                    <Route path="payment-form" element={<PaymentForm/>}/>
                </Route>

                <Route path="/" element={<MainLayout/>}>
                    <Route path="confirm-payment" element={<ConfirmPayment/>}/>
                    <Route path="error-payment" element={<ErrorPayment/>}/>
                    <Route path="valid-email" element={<ValidEmail/>}/>

                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/guest-registration" element={<GuestRegistration/>}/>
                </Route>

                {/* Routes protégées */}
                <Route element={<PrivateRoute redirectTo="/login"/>}>

                    {/* Routes création */}
                    <Route path="/" element={<HomeLayout/>}>
                        <Route path="/ademe" element={<Ademe/>}/>
                        <Route path="/validation" index element={<Validation/>}/>
                        <Route path="/financial/choice" index element={<Choice/>}/>
                        <Route path="/financial" index element={<Financial/>}/>
                        <Route path="/objective" index element={<Objective/>}/>
                    </Route>

                    {/* Routes connecté */}
                    <Route path="/" element={<SingleLayout/>}>
                        <Route path="/projects" index element={<ProjectsList/>}/>
                        <Route path="/single" index element={<SingleInfo/>}/>
                        <Route path="/users" index element={<UsersList/>}/>

                        <Route path="/profil/info" index element={<Info/>}/>
                        <Route path="/profil/billing" index element={<Billing/>}/>
                    </Route>
                </Route>

            </Routes>
        </Router>
    );
};

export default AppRoutes;
