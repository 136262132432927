import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import WInput from "../../../components/WInput";
import {useDispatch, useSelector} from "react-redux";
import {addUser, selectUser} from "../../../redux/slices/userSlice";
import {useMutation} from 'react-query';
import {updateUser} from "../../../api/user";
import {User} from "../../../api/interfaces/ProjectInterfaces";
import iconProfil from "../../../assets/icons/profil.svg";
import {useNavigate} from "react-router-dom";
import Remove from "../Remove";

const Info: React.FC = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // État local pour stocker les informations utilisateur
    const [formData, setFormData] = useState(user);
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        if (formData && name in formData) {
            setFormData({...formData, [name]: value});
        }
    };

    const {mutate} = useMutation<User, Error, User>(updateUser, {
        onSuccess: (data) => {
            dispatch(addUser(data));
            console.log(data);
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const handleSubmit = () => {
        if (formData && (confirmPassword === formData.password))
            mutate(formData);
    };

    const handleGoBilling = () => {
        navigate('/profil/billing');
    };

    if (!formData) {
        return <></>
    }

    return (<div className="flex flex-col text-left mb-6 w-full max-w-[1000px] relative mx-6">
            <div className="flex mt-6 mx-6">
                <img src={iconProfil} alt="Check Icon" className="h-7 text-gray-500"/>
                <div>
                    <div className="font-bold text-lg  ml-3 mr-3">Mon profil</div>
                </div>
            </div>
            <div className="flex justify-between mt-6 flex-wrap mx-6">
                <div className="mb-3">
                    <WButton variant="secondary" className="bottom-4 right-3 mr-3">Mes infos</WButton>
                    <WButton variant="primary" onClick={handleGoBilling} className="bottom-4 right-3">Facturation</WButton>
                </div>
                <div /* fin */>
                    <WButton variant="secondary"
                             className="bottom-4 right-3 !bg-red-600 !border-red-600">Déconnecter</WButton>
                </div>
            </div>

            {/* ... */}
            <div className="bg-white rounded-[10px] shadow-md mt-3 flex flex-col p-6 md:p-12 mx-6">
                <div className="font-bold">Informations personnelles</div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Prénom" name="prenom" value={formData.prenom || ""} onChange={handleChange}
                            required/>
                    <WInput placeholder="Nom" name="nom" value={formData.nom || ""} onChange={handleChange}
                            required/>
                </div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput name="email" placeholder="E-mail" value={formData.email || ""} onChange={handleChange}/>
                    <WInput name="mobile" placeholder="N° de téléphone" value={formData.mobile || ""}
                            onChange={handleChange} />
                </div>
                <div className="flex gap-6 mt-12">
                    <WInput placeholder="Mot de passe" name="password" type="password" value={formData.password}
                            onChange={handleChange} required/>
                </div>
                <div className="flex gap-4 mt-6">
                    <WInput placeholder="Confirmez votre mot de passe" name="confirmPassword" type="password"
                            onChange={handleChangePassword} required/>
                </div>
                <div className="flex font-bold mt-10">Informations de facturation</div>
                <div className="flex flex-col gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Société" name="company" required/>
                    <WInput placeholder="Rôle" name="role" required/>
                    <WInput placeholder="SIRET" name="siret" required/>
                    <WInput placeholder="Adresse" name="addr" required/>
                </div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Ville" name="city" required/>
                    <WInput placeholder="Code postal" name="postal" required/>
                </div>
                <div className="flex justify-center pt-12">
                    <WButton variant="secondary" onClick={handleSubmit}>Enregistrer les modifications</WButton>
                </div>
                <hr className="mb-6 mt-20 " />
                <Remove />
            </div>
        </div>
    );
};

export default Info;
