import React from 'react';
import WButton from "../../../components/Wbutton";
import iconProfil from "../../../assets/icons/profil.svg";
import WPrice from "../../../components/WPrice";
import {ArrowDownTrayIcon} from "@heroicons/react/16/solid";
import {useNavigate} from "react-router-dom";
import Remove from "../Remove";
import {useQuery} from "react-query";
import {getPlan} from "../../../api/payment";

const Billing: React.FC = () => {
    const navigate = useNavigate();

    const handleGoInfo = () => {
        navigate('/profil/info');
    };

    const {data: planPrice} = useQuery(['getPlanPrice'], async () => {
        return await getPlan();
    });

    return (<div className="flex flex-col text-left mb-6 relative mx-6">
            <div className="flex mt-6 mx-6">
                <img src={iconProfil} alt="Check Icon" className="h-7 text-gray-500"/>
                <div>
                    <div className="font-bold text-lg  ml-3 mr-3">Mon profil</div>
                </div>
            </div>
            <div className="flex justify-between mt-6 flex-wrap mx-6">
                <div className="mb-3">
                    <WButton variant="primary" onClick={handleGoInfo} className="bottom-4 right-3 mr-3">Mes infos</WButton>
                    <WButton variant="secondary" className="bottom-4 right-3">Facturation</WButton>
                </div>
                <div /* fin */>
                    <WButton variant="secondary"
                             className="bottom-4 right-3 !bg-red-600 !border-red-600">Déconnecter</WButton>
                </div>
            </div>

            {/* ... */}
            <div className="bg-white rounded-[10px] shadow-md mt-3 flex flex-col p-6 md:p-12 mx-6">

                <div className="flex flex-wrap flex-row m-auto">
                    <div className="mr-4">
                        <div className="font-bold border-b-4 border-gray-300 text-center">Votre abonnement actuel</div>
                        <div className="flex gap-4 mt-6 flex-wrap">
                            {planPrice && planPrice.offresDuMoment.slice(0, 1).map((elm) => {
                                return <div key={elm.id} className="m-2 mt-8">
                                    <WPrice elm={elm} year={false} />
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="ml-4">
                        <div className="font-bold border-b-4 border-green-600 text-center">Faites évoluer votre
                            solution
                        </div>
                        <div className="flex gap-4 mt-6 flex-wrap">
                            {planPrice && planPrice.offresDuMoment.slice(1).map((elm) => {
                                return <div key={elm.id} className="m-2 mt-8">
                                    <WPrice elm={elm} year={false} />
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="font-bold mt-10 mb-3">Moyen de payement</div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 leading-10 px-6 py-2">
                    <div>123xxx xxx xxx 456</div>
                    <div className="underline">Retirer</div>
                </div>

                <div className="font-bold mt-10 mb-3">Prochaine échéance</div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 leading-10 px-6 py-2">
                    <div>xx/xx/2024</div>
                    <div>Montant : <span className="text-[#60AD23] font-medium">49 €</span></div>
                </div>

                <div className="font-bold mt-10 mb-3">Historique de factures</div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] border-b leading-10 px-6 py-2">
                    <div className="flex">xx/xx/2024 <span className="ml-8">Facture N° :  xxxx-xxxx-xxxx</span></div>
                    <div className="flex items-center justify-center">Montant : <span
                        className="text-[#60AD23] font-medium ml-3">49 €</span>
                        <ArrowDownTrayIcon className="h-5 ml-3 text-gray-400"/>
                    </div>
                </div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 border-b leading-10 px-6 py-2">
                    <div className="flex">xx/xx/2024 <span className="ml-8">Facture N° :  xxxx-xxxx-xxxx</span></div>
                    <div className="flex items-center justify-center">Montant : <span
                        className="text-[#60AD23] font-medium ml-3">49 €</span>
                        <ArrowDownTrayIcon className="h-5 ml-3 text-gray-400"/>
                    </div>
                </div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] border-b leading-10 px-6 py-2">
                    <div className="flex">xx/xx/2024 <span className="ml-8">Facture N° :  xxxx-xxxx-xxxx</span></div>
                    <div className="flex items-center justify-center">Montant : <span
                        className="text-[#60AD23] font-medium ml-3">49 €</span>
                        <ArrowDownTrayIcon className="h-5 ml-3 text-gray-400"/>
                    </div>
                </div>
                <div
                    className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 border-b leading-10 px-6 py-2">
                    <div className="flex">xx/xx/2024 <span className="ml-8">Facture N° :  xxxx-xxxx-xxxx</span></div>
                    <div className="flex items-center justify-center">Montant : <span
                        className="text-[#60AD23] font-medium ml-3">49 €</span>
                        <ArrowDownTrayIcon className="h-5 ml-3 text-gray-400"/>
                    </div>
                </div>


                <hr className="mb-6 mt-20 " />
                <Remove/>
            </div>
        </div>
    );
};

export default Billing;
