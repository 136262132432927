import React, {useEffect} from 'react';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectAccount} from "../../../redux/slices/accountSlice";
import {useMutation} from "react-query";
import {createAccount} from "../../../api/payment";

const ConfirmPayment: React.FC = () => {

    const navigate = useNavigate();
    const account = useSelector(selectAccount);

    const {mutate} = useMutation(createAccount, {
        onSuccess: () => {
            console.log("Account created successfully!");
        },
        onError: (error) => {
            console.error("Error creating account:", error);
        }
    });

    useEffect(() => {
        if (account) {
            mutate(account);
        } else {
            console.error("Account data is missing");
        }
    }, [account, mutate]);

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">

            <WInfo>Votre payement à été effectué avec succès. Merci !</WInfo>

            <div className="font-bold text-lg mt-6 ml-3 mr-3">Dernière étape : Vérifiez votre adresse e-mail</div>
            <div className="font-normal m-auto ml-3 mr-3"> Cliquez sur le lien dans l’e-mail que nous vous avons envoyé.<br/>
                Vérifiez dans vos spams si vous n’avez pas reçu notre e-mail dans les 5 minutes.<br/>
                Renseignez notre adresse email dans vos contact pour que nos e-mails n’arrivent pas dans vos spams</div>

            <div className="flex gap-6 mt-10 m-auto flex-col ml-3 mr-3">
                <WButton variant="secondary" onClick={() => navigate('/login')}>
                    Connectez-vous et créez votre premier projet
                </WButton>
                <WButton>
                    Renvoyer l’e-mail de confirmation
                </WButton>
            </div>

        </div>);
};

export default ConfirmPayment;