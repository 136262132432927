import { createSlice } from '@reduxjs/toolkit'

// Define a type for the slice state
interface IUserData {
    isAuthenticated: boolean,
    token: string|null
}

// Define the initial state using that type
const initialState: IUserData = {
    isAuthenticated: false,
    token: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.isAuthenticated = true;
            state.token = action.payload.accessToken;
        },
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
        },
        reset() {
            return initialState;
        }
    },

})

// Sélecteur pour obtenir le projet actuel
export const selectUser = (state: { auth: IUserData }) => {
    return state.auth;
};

export const {
    loginSuccess,
    logout,
    reset } = authSlice.actions

export default authSlice.reducer